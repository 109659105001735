<template>
  <div class="">
    <transition name="component-trans" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
